<template>
  <PageHeader title="Reports"></PageHeader>
  <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-8">
    <div v-for="category in reportsComputed" :key="category.title">
      <Card v-if="category.visible">
        <CardBody>
          <CardTitle :title="category.title"></CardTitle>
          <ListGroup
            v-for="section in category.sections"
            :key="section.id"
            :class="{ 'mb-5 last-of-type:mb-0': category.sections.length > 1 }"
          >
            <ListGroupItem v-for="item in section.items" :key="item.title" class="flex justify-between items-center" :route="item.route">
              <div v-if="item.visible" class="flex items-center">
                <Icon :type="item.icon" />
                <span class="ml-2">{{ item.localeKey != null ? $t(item.localeKey) : item.title }}</span>
              </div>
              <div class="flex items-center">
                <Chip v-if="item.badge" class="mr-2" size="sm" :severity="item.badge.variant" label>{{ item.badge.title }}</Chip>
                <Icon type="chevron-right" />
              </div>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import CardBody from '@/components/card/CardBody.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'

import { useUserStore } from '@/stores/UserStore'
import { useAbility } from '@casl/vue'

export default {
  name: 'Reports',
  components: {
    PageHeader,
    Card,
    CardTitle,
    CardBody,
    Icon,
    Chip,
    ListGroup,
    ListGroupItem
  },
  setup() {
    const userStore = useUserStore()
    const { can } = useAbility()

    const reports = ref([
      {
        title: 'Call Agents',
        visible: userStore.details.type === 1 && can('view_reports_agents'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Statistics',
                route: '/reports/call-agents/statistics',
                description: '',
                icon: 'chart-line',
                visible: true
              },
              {
                title: 'KPIs',
                route: '/reports/call-agents/kpis',
                description: '',
                icon: 'gauge',
                visible: true
              },
              {
                title: 'League & Achievements',
                // route: '/reports/call-agents/league',
                description: '',
                icon: 'ranking-star',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: true
              },
              // {
              //   title: 'Quality Check',
              //   route: '/reports/call-agents/quality-check',
              //   description: '',
              //   icon: 'user-check',
              //   badge: {
              //     title: 'Coming Soon',
              //     variant: 'default'
              //   }
              // },
              {
                title: 'Status & Activity Timeline',
                route: '/reports/call-agents/status-timeline',
                description: '',
                icon: 'wave-pulse',
                visible: true
              },
              {
                title: 'Status & Activity Summary',
                route: '/reports/call-agents/status-summary',
                description: '',
                icon: 'wave-pulse',
                visible: true
              },
              {
                title: 'Allocations & Availability',
                // route: '/reports/call-agents/allocations',
                description: '',
                icon: 'map-pin',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: true
              }
              // {
              //   title: 'Shifts',
              //   route: '/reports/staff/shifts',
              //   description: '',
              //   icon: 'calendar-circle-user',
              //   badge: {
              //     title: 'Coming Soon',
              //     variant: 'default'
              //   }
              // }
            ]
          }
        ]
      },
      {
        title: 'Engagement',
        visible: userStore.details.type === 1 && can('view_reports_engagement'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Calls',
                route: '/reports/engagement/calls',
                description: '',
                icon: 'phone',
                visible: true
              },
              {
                title: 'Emails',
                route: '/reports/engagement/emails',
                description: '',
                icon: 'envelope',
                visible: true
              },
              {
                title: 'SMS',
                route: '/reports/engagement/sms',
                description: '',
                icon: 'sms',
                visible: true
              }
            ]
          },
          {
            id: 2,
            items: [
              {
                title: 'Insights',
                // route: '/reports/engagement/best-time-to-call',
                description: '',
                icon: 'lightbulb-on',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: true
              }
            ]
          }
        ]
      },
      {
        title: 'Appointments',
        visible: can('view_reports_appointments'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'List',
                route: '/reports/appointments/list',
                description: '',
                icon: 'bars',
                visible: true
              },
              {
                title: 'Cancelled & No-Show',
                route: '/reports/appointments/cancelled-and-no-show',
                description: '',
                icon: 'x',
                visible: true
              },
              {
                title: 'Conversions',
                route: '/reports/appointments/conversions',
                description: '',
                icon: 'calendar-check',
                visible: true
              }
            ]
          }
        ]
      },
      {
        title: 'Dealerships',
        visible: can('view_reports_dealerships'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Statistics',
                route: '/reports/dealerships/statistics',
                description: '',
                icon: 'chart-line',
                visible: true
              },
              {
                title: 'Targets & Results',
                // route: '/reports/engagement/targets-and-results',
                description: '',
                icon: 'bullseye',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: userStore.details.type === 1
              },
              {
                title: 'Conversions',
                // route: '/reports/dealership/conversions',
                description: '',
                icon: 'person-walking-arrow-loop-left',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: userStore.details.type === 1
              },
              {
                title: 'Sales Person Conversions',
                // route: '/reports/dealership/sales-person-conversions',
                description: '',
                icon: 'person-walking-arrow-loop-left',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: userStore.details.type === 1
              },
              {
                title: 'Data',
                route: '/reports/dealerships/data',
                description: '',
                icon: 'database',
                badge: {
                  title: 'New',
                  variant: 'success'
                },
                visible: userStore.details.type === 1
              }
            ]
          }
        ]
      },
      {
        title: 'Customers',
        visible: can('view_reports_customers'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Ending Tracker',
                // route: '/reports/customers/ending-tracker',
                description: '',
                icon: 'hourglass-end',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: userStore.details.type === 1
              },
              {
                title: 'Appointed Customers',
                route: '/reports/customers/appointed-customers',
                description: '',
                icon: 'calendar',
                visible: true
              }
            ]
          }
        ]
      },
      // {
      //   title: 'Leads',
      //   visible: true,
      //   sections: [
      //     {
      //       id: 1,
      //       items: [
      //         {
      //           title: 'Overview',
      //           route: '/reports/leads/overview',
      //           description: '',
      //           icon: 'chart-pie-simple',
      //           badge: {
      //             title: 'Coming Soon',
      //             variant: 'default'
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        title: 'ReLoop',
        visible: (can('access_feature_reloop') || userStore.details.type === 1) && can('view_reports_reloop'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Overview',
                route: '/dashboard/reloop',
                description: '',
                icon: 'chart-pie-simple',
                visible: true
              },
              {
                title: 'Submissions',
                route: '/reports/reloop/submissions',
                description: '',
                icon: 'inbox-in',
                visible: true
              },
              {
                title: 'Scorecard',
                route: '/reports/reloop/scorecard',
                description: '',
                icon: 'table',
                visible: true
              }
            ]
          }
        ]
      },
      {
        title: 'Campaigns',
        visible: userStore.details.type === 1 && can('view_reports_campaigns'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Performance',
                // route: '/reports/campaigns/performance',
                description: '',
                icon: 'chart-line',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                },
                visible: true
              }
            ]
          }
        ]
      },
      // {
      //   title: 'Qualify',
      //   visible: userStore.details.type === 1,
      //   sections: [
      //     {
      //       id: 1,
      //       items: []
      //     }
      //   ]
      // },
      // {
      //   title: 'Sale',
      //   visible: true,
      //   sections: [
      //     {
      //       id: 1,
      //       items: []
      //     }
      //   ]
      // },
      {
        title: 'Finance',
        visible: userStore.details.type === 1 && can('view_reports_finance'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'Overview',
                route: '/dashboard/finance',
                description: '',
                icon: 'chart-pie-simple',
                badge: {
                  title: 'Not Working',
                  variant: 'warning'
                },
                visible: true
              },
              {
                title: 'Invoicing',
                route: '/finance/invoicing',
                description: '',
                icon: 'file-invoice-dollar',
                visible: true
              }
            ]
          }
        ]
      },
      {
        title: 'Vehicles',
        visible: userStore.details.type === 1 && can('view_reports_finance'),
        sections: [
          {
            id: 1,
            items: [
              {
                title: 'ReAuction List',
                route: '/reports/vehicles/reauction-list',
                description: '',
                icon: 'car',
                badge: {
                  title: 'New',
                  variant: 'success'
                },
                visible: true
              }
            ]
          }
        ]
      }
    ])

    const reportsComputed = computed(() => {
      let visibleReports = []
      // remove categories that have visible=false
      visibleReports = reports.value.filter(category => category.visible)
      // remove items that have visible=false
      visibleReports = visibleReports.map(category => {
        return {
          ...category,
          sections: category.sections.map(section => {
            return {
              ...section,
              items: section.items.filter(item => item.visible !== false)
            }
          })
        }
      })

      return visibleReports
    })

    return { reportsComputed }
  }
}
</script>

<style></style>
