<template>
  <PageHeader
    :title="$t('ui.entities.dealership')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.dealership', 2), to: '/settings/dealerships' },
      { label: dealership.basicDetails.displayName, to: '/settings/dealerships/' + dealership.basicDetails.id }
    ]"
  >
    <template #right></template>
  </PageHeader>

  <div v-if="dealershipRequest.isLoading.value || dealership == null">
    <div class="grid sm:grid-cols-1 md:grid-cols-4 gap-x-8">
      <Skeleton width="100%" height="14rem" />
      <div class="md:col-span-3">
        <Skeleton width="100%" height="80vh" />
      </div>
    </div>
  </div>

  <div v-else class="grid sm:grid-cols-1 md:grid-cols-4 gap-x-8">
    <div>
      <Tabs v-model="currentTab" :tabs="tabs" direction="vertical" type="pills-rail-block" class="mb-8 item" />
    </div>
    <div class="md:col-span-3">
      <div v-if="currentTab == 'basic'">
        <Card>
          <CardBody>
            <CardTitle :title="$t('ui.common.basic-info')" />
            <div class="flex items-center mb-8">
              <div class="flex flex-col gap-4">
                <Switch v-model="dealership.basicDetails.isActive" label="Active" @change="onIsActiveToggleChange" />
                <Switch v-model="dealership.basicDetails.showInReports" label="Show in Reports" />
              </div>
              <!-- <div class="h-20 w-20 flex items-center justify-center bg-slate-100 rounded-full p-3">
                <img :src="manufacturerLogo" alt="" class="w-full" />
              </div> -->
            </div>

            <div class="grid md:grid-cols-2 gap-x-8">
              <FormGroup :label="$t('ui.common.name')" :is-required="v$.basicDetails.name.required">
                <Input v-model="dealership.basicDetails.name" :has-error="v$.basicDetails.name.$error" />
                <InputError :has-error="v$.basicDetails.name.$error">{{ v$.basicDetails.name.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.common.display-name')" :is-required="v$.basicDetails.displayName.required">
                <Input v-model="dealership.basicDetails.displayName" :has-error="v$.basicDetails.displayName.$error" />
                <InputError :has-error="v$.basicDetails.displayName.$error">{{
                  v$.basicDetails.displayName.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.common.location-name')" :is-required="v$.basicDetails.locationName.required">
                <Input v-model="dealership.basicDetails.locationName" :has-error="v$.basicDetails.locationName.$error" />
                <InputError :has-error="v$.basicDetails.locationName.$error">{{
                  v$.basicDetails.locationName.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.common.date-time.timezone')" :is-required="v$.basicDetails.timeZone.required">
                <Multiselect
                  v-model="dealership.basicDetails.timeZone"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  :hide-selected="false"
                  :options="timeZoneList"
                  :can-deselect="false"
                  :class="{ 'has-error': v$.basicDetails.timeZone.$error }"
                >
                </Multiselect>
                <InputError :has-error="v$.basicDetails.timeZone.$error">{{ v$.basicDetails.timeZone.$errors[0]?.$message }}</InputError>
              </FormGroup>
            </div>

            <div class="grid md:grid-cols-2 gap-x-8">
              <FormGroup :label="$t('ui.entities.manufacturer')" :is-required="v$.basicDetails.manufacturerId.required">
                <Multiselect
                  v-model="dealership.basicDetails.manufacturerId"
                  mode="single"
                  :close-on-select="true"
                  :searchable="false"
                  :hide-selected="false"
                  :options="manufacturerOptions"
                  :can-deselect="false"
                  :class="{ 'has-error': v$.basicDetails.manufacturerId.$error }"
                  :disabled="!$can('update_dealership_locked_data')"
                >
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img :src="value.logo" width="25" class="mr-3" />
                      {{ value.label }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <img :src="option.logo" width="20" class="mr-3" />
                    {{ option.label }}</template
                  >
                </Multiselect>
                <InputError :has-error="v$.basicDetails.manufacturerId.$error">{{
                  v$.basicDetails.manufacturerId.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.group')" :is-required="v$.basicDetails.groupId.required">
                <Multiselect
                  v-model="dealership.basicDetails.groupId"
                  mode="single"
                  :close-on-select="true"
                  :searchable="false"
                  :hide-selected="false"
                  :options="groupOptions"
                  :can-deselect="false"
                  :class="{ 'has-error': v$.basicDetails.groupId.$error }"
                  :disabled="!$can('update_dealership_locked_data')"
                >
                </Multiselect>
                <InputError :has-error="v$.basicDetails.groupId.$error">{{ v$.basicDetails.groupId.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.comms.phone-number')">
                <InputPhone v-model="dealership.basicDetails.telephone" />
              </FormGroup>
              <FormGroup :label="$t('ui.common.website')">
                <Input v-model="dealership.basicDetails.website" icon="globe-pointer" />
              </FormGroup>
            </div>
            <CardTitle title="Address" icon="map-marker" class="mt-8" />
            <div class="grid md:grid-cols-2 gap-x-8">
              <!-- <FormGroup class="col-span-2" :label="$t('ui.common.search')">
                {{ addressSearchValue }}
                <Input v-model="addressSearchValue" icon="search" />
              </FormGroup> -->
              <FormGroup :label="$t('ui.entities.address.line-1')" :is-required="v$.basicDetails.address.line1.required">
                <Input v-model="dealership.basicDetails.address.line1" :has-error="v$.basicDetails.address.line1.$error" />
                <InputError :has-error="v$.basicDetails.address.line1.$error">{{
                  v$.basicDetails.address.line1.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.line-2')" :is-required="v$.basicDetails.address.line2.required">
                <Input v-model="dealership.basicDetails.address.line2" :has-error="v$.basicDetails.address.line2.$error" />
                <InputError :has-error="v$.basicDetails.address.line2.$error">{{
                  v$.basicDetails.address.line2.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.city')" :is-required="v$.basicDetails.address.city.required">
                <Input v-model="dealership.basicDetails.address.city" :has-error="v$.basicDetails.address.city.$error" />
                <InputError :has-error="v$.basicDetails.address.city.$error">{{
                  v$.basicDetails.address.city.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.county')" :is-required="v$.basicDetails.address.county.required">
                <Input v-model="dealership.basicDetails.address.county" :has-error="v$.basicDetails.address.county.$error" />
                <InputError :has-error="v$.basicDetails.address.county.$error">{{
                  v$.basicDetails.address.county.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.post-code')" :is-required="v$.basicDetails.address.postcode.required">
                <Input v-model="dealership.basicDetails.address.postcode" :has-error="v$.basicDetails.address.postcode.$error" />
                <InputError :has-error="v$.basicDetails.address.postcode.$error">{{
                  v$.basicDetails.address.postcode.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.country')" :is-required="v$.basicDetails.address.country.required">
                <Multiselect
                  v-model="dealership.basicDetails.address.country"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  :hide-selected="false"
                  :options="countriesList"
                  :can-deselect="false"
                  :is-object="true"
                  :class="{ 'has-error': v$.basicDetails.address.country.$error }"
                  track-by="name"
                  label="name"
                  value-prop="code"
                >
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img :src="value.image" width="25" class="mr-3" />
                      {{ value.name }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <img :src="option.image" width="25" class="mr-3" />
                    {{ option.name }}</template
                  >
                </Multiselect>
                <InputError :has-error="v$.basicDetails.address.country.$error">{{
                  v$.basicDetails.address.country.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
            </div>
          </CardBody>

          <SectionFooter>
            <Button label="Save Changes" type="primary" :is-loading="formState.basicDetailsSubmitting" @click="submitBasicForm" />
          </SectionFooter>
        </Card>
      </div>

      <div v-if="currentTab == 'users'">
        <Card>
          <CardBody>
            <CardTitle title="Users" :has-margin="false" />
          </CardBody>
          <Separator no-margin />
          <TableBasic>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <!-- <th>Verification</th> -->
                <th>Email Address</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td class="flex items-center gap-3">
                  <Avatar :url="user.profilePicURL" size="md" :text="user.initials" />
                  <div>
                    <div class="font-medium">{{ user.fullName }}</div>
                    <div class="text-sm">{{ user.jobTitle }}</div>
                  </div>
                </td>
                <td>
                  <Chip v-if="user.active" label outlined size="sm" dot severity="success">Active</Chip>
                  <Chip v-else label outlined size="sm" dot severity="danger">Disabled</Chip>
                </td>
                <!-- <td>
                  <Chip v-if="user.accountVerified" label outlined size="sm" dot severity="success">Verified</Chip>
                  <Chip v-else label outlined size="sm" dot severity="danger">Not Verified</Chip>
                </td> -->
                <td>{{ user.email }}</td>
                <td>
                  <div class="flex justify-end">
                    <Button
                      v-tippy="$t('ui.entities.setting', 2)"
                      icon="cog"
                      plain
                      severity="secondary"
                      @click="$router.push('/settings/users/' + user.id)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </TableBasic>
        </Card>
      </div>

      <div v-if="currentTab == 'availability'">
        <Card>
          <CardBody>
            <CardTitle title="Appointment Availability" />
          </CardBody>
          <SectionFooter>
            <Button label="Save Changes" type="primary" :is-loading="formState.basicDetailsSubmitting" @click="submitBasicForm" />
          </SectionFooter>
        </Card>
      </div>

      <div v-if="currentTab == 'features'">
        <Card>
          <CardBody>
            <CardTitle title="Features" subtitle="Enabling/Disabling a feature will not alter this dealership's billing." />

            <div class="flex flex-col">
              <FormGroup>
                <Switch
                  v-model="dealership.features.dataChangeAcknowledge"
                  label="Data Change Acknowledge"
                  description="Changes to customer data will require acknowledgement from the dealership to confirm that they have received the change"
                />
              </FormGroup>
              <FormGroup>
                <Switch v-model="dealership.features.leads" label="Leads" description="Manage leads generated by external systems" />
              </FormGroup>
              <FormGroup>
                <Switch v-model="dealership.features.leadsPostHandover" label="Leads - Post Handover" />
              </FormGroup>
              <FormGroup>
                <Switch v-model="dealership.features.leadsMotability" label="Leads - Motability" disabled chip-label="Not in Use" />
              </FormGroup>
              <FormGroup>
                <Switch
                  v-model="dealership.features.usedStock"
                  label="Used Stock"
                  disabled
                  chip-label="Not in Use"
                  description="Allow importing of used stock from an external source"
                />
              </FormGroup>
              <FormGroup>
                <Switch
                  v-model="dealership.features.switch"
                  label="Switch"
                  disabled
                  description="Customer <-> Used Stock matching. Requires Used Stock to be enabled."
                />
              </FormGroup>
              <FormGroup>
                <Switch
                  v-model="dealership.features.auction"
                  label="ReAuction"
                  description="Customers can put their vehicle up for auction on a dealership, group and national level"
                  chip-label="NEW!"
                />
              </FormGroup>
              <FormGroup>
                <Switch
                  v-model="dealership.features.reloop"
                  label="ReLoop"
                  description="Post-appointment emails asking customer for feedback"
                />
              </FormGroup>
              <FormGroup>
                <Switch
                  v-model="dealership.features.advancedReporting"
                  label="Advanced Reporting"
                  description="Enhanced insights, reports and dashboards"
                  disabled
                  chip-label="Not in Use"
                />
              </FormGroup>
            </div>
          </CardBody>
          <SectionFooter>
            <Button label="Save Changes" type="primary" :is-loading="formState.featuresSubmitting" @click="submitFeaturesForm" />
          </SectionFooter>
        </Card>
      </div>

      <div v-if="currentTab == 'opening-hours'">
        <Card>
          <CardBody>
            <CardTitle :title="$t('ui.entities.opening-hours')" />
            <div class="flex flex-col gap-2">
              <div v-for="(dow, i) in dealership.openingHours?.sales" :key="i" class="grid grid-cols-7 lg:grid-cols-11 w-full gap-2">
                <div class="flex items-center row-span-2">
                  {{
                    dayjs()
                      .day(dow.dayOfWeek + 1)
                      .format('ddd')
                  }}
                </div>
                <div class="flex items-center gap-2 col-span-4">
                  <Input v-model="dow.from" :readonly="dow.isClosed" :placeholder="dow.isClosed ? 'Closed' : null" class="w-full" />
                  <Icon type="arrow-right" />
                  <Input v-model="dow.to" :readonly="dow.isClosed" :placeholder="dow.isClosed ? 'Closed' : null" class="w-full" />
                </div>
                <div class="flex items-center col-span-2 gap-2 w-full ml-4">
                  <Switch v-model="dow.isClosed" label="Closed" @change="toggleDayClosed(i)" />
                </div>
                <div class="col-span-6 lg:col-span-4">
                  <Input v-model="dow.remarks" placeholder="Add note" />
                </div>
              </div>
            </div>
          </CardBody>
          <SectionFooter>
            <Button label="Save Changes" type="primary" :is-loading="formState.openingHoursSubmitting" @click="submitOpeningHoursForm" />
          </SectionFooter>
        </Card>
      </div>

      <div v-if="currentTab == 'billing'">
        <Card>
          <CardBody>
            <CardTitle title="Billing" />
            <Switch v-model="dealership.billing.isActive" label="Enabled" />
          </CardBody>
          <SectionFooter>
            <Button label="Save Changes" type="primary" :is-loading="formState.billingSubmitting" @click="submitBillingForm" />
          </SectionFooter>
        </Card>

        <Card>
          <CardBody>
            <div class="flex justify-between items-start">
              <CardTitle
                title="Invoice Items"
                :subtitle="
                  dealership.billing?.invoiceTemplate
                    ? 'All fees are pre-tax'
                    : 'Not linked to any invoice templates. To add to an existing or new template, please contact the IT department.'
                "
              />
              <div v-if="dealership.billing?.invoiceTemplate" class="flex gap-4 items-center">
                <div class="text-muted">Template: #{{ dealership.billing?.invoiceTemplate?.id }}</div>
                <Button
                  disabled
                  outlined
                  label="View Full Invoice"
                  @click="$router.push('/finance/invoice-template/' + dealership.billing?.invoiceTemplate?.id)"
                />
              </div>
            </div>
            <InvoiceItems
              v-if="dealership.billing?.invoiceTemplate"
              v-model="dealership.billing.invoiceTemplate.items"
              :invoice-template-id="dealership.billing?.invoiceTemplate?.id"
              :dealership-id="dealership.basicDetails.id"
              :read-only="!$can('manage_dealership_billing')"
              @item-validated="validateInvoiceItem"
            />
            <div v-if="dealership.billing?.invoiceTemplate" class="flex justify-end mt-4">
              <div class="w-[150px] mr-[112px]">
                <div class="flex justify-between">
                  <div>Subtotal</div>
                  <div>{{ numberFormatter(billingTotals.subTotal, 'normal', 2) }}</div>
                </div>
                <div class="flex justify-between">
                  <div>Discount</div>
                  <div>-{{ numberFormatter(billingTotals.discount, 'normal', 2) }}</div>
                </div>
                <div class="flex justify-between">
                  <div>Tax</div>
                  <div>{{ numberFormatter(billingTotals.tax, 'normal', 2) }}</div>
                </div>
                <div class="flex justify-between font-semibold">
                  <div>Total</div>
                  <div>{{ numberFormatter(billingTotals.total, 'normal', 2) }}</div>
                </div>
              </div>
            </div>
          </CardBody>
          <SectionFooter>
            <Button v-if="dealership.billing?.invoiceTemplate" label="Save Changes" type="primary" @click="saveInvoiceChanges" />
          </SectionFooter>
        </Card>
      </div>

      <div v-if="currentTab == 'config'">
        <Card>
          <CardBody class="flex flex-col gap-6">
            <div>
              <SectionLabel label="Customer Import" />
              <div class="grid md:grid-cols-2 gap-x-8">
                <FormGroup label="Source" :is-required="v$.config.importSource.required">
                  <Multiselect
                    v-model="dealership.config.importSource"
                    mode="single"
                    :close-on-select="true"
                    :searchable="false"
                    :hide-selected="false"
                    :options="['k2k', 'p360', 'blackhorse', 'emaster', 'evergreen-bank']"
                    :can-deselect="false"
                    :class="{ 'has-error': v$.config.importSource.$error }"
                    :disabled="!$can('update_dealership_locked_data')"
                  ></Multiselect>
                  <InputError :has-error="v$.config.importSource.$error">{{ v$.config.importSource.$errors[0]?.$message }}</InputError>
                </FormGroup>
                <FormGroup label="Identifier" :is-required="v$.config.importSourceId.required">
                  <Input
                    v-model="dealership.config.importSourceId"
                    :has-error="v$.config.importSourceId.$error"
                    :readonly="!$can('update_dealership_locked_data')"
                  />
                  <InputError :has-error="v$.config.importSourceId.$error">{{ v$.config.importSourceId.$errors[0]?.$message }}</InputError>
                </FormGroup>
              </div>
            </div>

            <div>
              <SectionLabel label="Used Stock" />
              <div class="grid md:grid-cols-2 gap-x-8">
                <FormGroup label="Source" :is-required="v$.config.usedStockLocationSource.required">
                  <Multiselect
                    v-model="dealership.config.usedStockLocationSource"
                    mode="single"
                    :close-on-select="true"
                    :searchable="false"
                    :hide-selected="false"
                    :options="['bluesky', 'gforces']"
                    :can-deselect="false"
                    :class="{ 'has-error': v$.config.usedStockLocationSource.$error }"
                    :disabled="!$can('update_dealership_locked_data')"
                  ></Multiselect>
                  <InputError :has-error="v$.config.usedStockLocationSource.$error">{{
                    v$.config.usedStockLocationSource.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
                <FormGroup label="Identifier" :is-required="v$.config.usedStockLocationId.required">
                  <Input
                    v-model="dealership.config.usedStockLocationId"
                    :has-error="v$.config.usedStockLocationId.$error"
                    :readonly="!$can('update_dealership_locked_data')"
                  />
                  <InputError :has-error="v$.config.usedStockLocationId.$error">{{
                    v$.config.usedStockLocationId.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
              </div>
            </div>

            <div>
              <SectionLabel label="ReAuction" class="mb-2" />
              <div class="grid md:grid-cols-2 gap-x-8">
                <FormGroup
                  label="Notify Email Addresses"
                  :is-required="v$.config.reAuctionNotificationEmails.required"
                  info="Enter an email address to notify when a new ReAuction is available.<br>Separate multiple email addresses with a comma."
                >
                  <Input
                    v-model="dealership.config.reAuctionNotificationEmails"
                    :has-error="v$.config.reAuctionNotificationEmails.$error"
                  />
                  <InputError :has-error="v$.config.reAuctionNotificationEmails.$error">{{
                    v$.config.reAuctionNotificationEmails.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
              </div>
            </div>
          </CardBody>
          <SectionFooter>
            <Button label="Save Changes" type="primary" :is-loading="formState.configSubmitting" @click="submitConfigForm" />
          </SectionFooter>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import { useAbility } from '@casl/vue'
import axios from 'axios'

import Skeleton from 'primevue/skeleton'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import notification from '@/utilities/notification'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Input from '@/components/forms/Input.vue'
import Icon from '@/components/icon/Icon.vue'
import Switch from '@/components/forms/Switch.vue'
import InputError from '@/components/forms/InputError.vue'
import Button from '@/components/button/Button.vue'
import TableBasic from '@/components/tables/TableBasic.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Chip from '@/components/chip/Chip.vue'
import Separator from '@/components/separator/Separator.vue'
import InputPhone from '@/components/forms/InputPhone.vue'

import SectionFooter from '@/components/sections/SectionFooter.vue'
import SectionLabel from '@/components/sections/SectionLabel.vue'

import InvoiceItems from '@/components/invoice/InvoiceItems.vue'

export default {
  components: {
    PageHeader,
    Card,
    CardBody,
    Tabs,
    FormGroup,
    Input,
    Switch,
    Multiselect,
    InputError,
    Button,
    CardTitle,
    TableBasic,
    InvoiceItems,
    Skeleton,
    SectionLabel,
    SectionFooter,
    Avatar,
    Chip,
    Separator,
    InputPhone,
    Icon
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { can } = useAbility()

    const userStore = useUserStore()

    const dealershipRequest = useApiRequest()
    const dealershipSubmitFormRequest = useApiRequest()

    const users = ref([])

    useApiRequest()
      .sendRequest({ endpoint: '/v1/users', params: { userType: 'externalUsers', dealerships: route.params.id } })
      .then(response => {
        users.value = response.data.data
      })

    const manufacturerOptions = ref([])
    const groupOptions = ref([])

    useApiRequest()
      .sendRequest({ endpoint: '/v1/manufacturers' })
      .then(response => {
        manufacturerOptions.value = response.data.data.map(manufacturer => {
          return {
            value: manufacturer.id,
            label: manufacturer.name,
            logo: manufacturer.logoUrl
          }
        })
      })

    useApiRequest()
      .sendRequest({ endpoint: '/v1/groups' })
      .then(response => {
        groupOptions.value = response.data.data.map(group => {
          return {
            value: group.id,
            label: group.name,
            logo: group.logoUrl
          }
        })
      })

    const countriesList = ref([])

    axios.get('https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json', { withCredentials: false }).then(response => {
      // console.log('Response: ' + JSON.stringify(response.data))

      // Assuming each item in the response has a "name" property that holds the country name
      let sortedCountries = response.data.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

      countriesList.value = sortedCountries
    })

    const timeZoneList = ref([])
    axios.get('https://api.timezonedb.com/v2.1/list-time-zone?key=PJ3WFAM872HM&format=json', { withCredentials: false }).then(response => {
      timeZoneList.value = response.data.zones.map(timezone => {
        return {
          value: timezone.zoneName,
          label: timezone.zoneName
        }
      })
    })

    const dealership = ref({
      basicDetails: {
        id: null,
        name: null,
        displayName: null,
        locationName: null,
        manufacturerId: null,
        manufacturerName: null,
        groupId: null,
        groupName: null,
        telephone: null,
        website: null,
        timeZone: null,
        address: {
          line1: null,
          line2: null,
          line3: null
        },
        isActive: null,
        showInReports: null
      },
      comms: {},
      availability: {},
      features: {
        dataChangeAcknowledge: null,
        leads: null,
        leadsPostHandover: null,
        leadsMotability: null,
        advancedReporting: null,
        switch: null,
        usedStock: null,
        auction: null,
        reloop: null
      },
      billing: {
        isActive: null,
        invoiceTemplate: {
          id: null,
          items: []
        }
      },
      config: {
        importSource: null,
        importSourceId: null,
        usedStockLocationSource: null,
        usedStockLocationId: null
      }
    })

    const openingHoursConfig = {
      sales: [
        {
          dayOfWeek: 0,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        },
        {
          dayOfWeek: 1,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        },
        {
          dayOfWeek: 2,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        },
        {
          dayOfWeek: 3,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        },
        {
          dayOfWeek: 4,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        },
        {
          dayOfWeek: 5,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        },
        {
          dayOfWeek: 6,
          from: null,
          to: null,
          isClosed: false,
          remarks: null
        }
      ]
    }

    dealershipRequest
      .sendRequest({ endpoint: '/v1/dealerships/' + route.params.id, method: 'GET', params: { dataReturnLevel: 'all' } })
      .then(response => {
        // Basic Details
        dealership.value.basicDetails = {
          id: response.data.data.id,
          name: response.data.data.name,
          displayName: response.data.data.displayName,
          locationName: response.data.data.locationName,
          manufacturerId: response.data.data.manufacturerID,
          manufacturerName: response.data.data.manufacturerName,
          groupId: response.data.data.groupID,
          groupName: response.data.data.groupName,
          telephone: response.data.data.telephone,
          website: response.data.data.website,
          timeZone: response.data.data.timeZone,
          isActive: response.data.data.isActive,
          showInReports: response.data.data.showInReports,
          address: {
            line1: response.data.data.address.line1,
            line2: response.data.data.address.line2,
            line3: response.data.data.address.line3,
            city: response.data.data.address.city,
            county: response.data.data.address.county,
            postcode: response.data.data.address.postcode,
            country: response.data.data.address.country
          }
        }

        // Features
        dealership.value.features = {
          dataChangeAcknowledge: response.data.data.features.dataChangeAcknowledge,
          leads: response.data.data.features.leads,
          leadsPostHandover: response.data.data.features.leadsPostHandover,
          leadsMotability: response.data.data.features.leadsMotability,
          advancedReporting: response.data.data.features.advancedReporting,
          switch: response.data.data.features.switch,
          usedStock: response.data.data.features.usedStock,
          auction: response.data.data.features.auction,
          reloop: response.data.data.features.reloop
        }

        // Opening Hours
        dealership.value.openingHours = {
          sales: response.data.data.openingHours.sales ?? openingHoursConfig.sales
        }

        // Billing
        dealership.value.billing = {
          isActive: response.data.data.config.billingIsActive
        }

        // config
        dealership.value.config = {
          importSource: response.data.data.config.importSource,
          importSourceId: response.data.data.config.importSourceId,
          usedStockLocationSource: response.data.data.config.usedStockLocationSource,
          usedStockLocationId: response.data.data.config.usedStockLocationId,
          reAuctionNotificationEmails: response.data.data.config.reAuctionNotificationEmails
        }

        // Fetch invoice items
        useApiRequest()
          .sendRequest({ endpoint: '/v1/finance/invoice-templates', method: 'GET', params: { dealerships: route.params.id } })
          .then(response => {
            if (response.data.data) {
              dealership.value.billing.invoiceTemplate = response.data.data[0]

              let dealershipSpecificItems = dealership.value.billing.invoiceTemplate.items?.filter(
                i => i.invoiceTemplateDealershipId == route.params.id
              )
              dealership.value.billing.invoiceTemplate.items = dealershipSpecificItems
            }
          })
      })

    const currentTab = ref(route.params.tab)
    const tabs = ref([
      {
        id: 'basic',
        label: t('ui.common.basic-info'),
        icon: 'building',
        to: '/settings/dealerships/' + route.params.id
      },
      {
        id: 'users',
        label: t('ui.common.user', 2),
        icon: 'users',
        to: '/settings/dealerships/' + route.params.id + '/users',
        isHidden: computed(() => !can('manage_users'))
      },
      {
        id: 'availability',
        label: t('ui.entities.availability'),
        icon: 'calendar-check',
        to: '/settings/dealerships/' + route.params.id + '/availability',
        isHidden: true
      },
      {
        id: 'features',
        label: t('ui.entities.features'),
        icon: 'star',
        to: '/settings/dealerships/' + route.params.id + '/features',
        isHidden: computed(() => userStore.details.type != 1)
      },
      {
        id: 'opening-hours',
        label: t('ui.entities.opening-hours'),
        icon: 'clock',
        to: '/settings/dealerships/' + route.params.id + '/opening-hours',
        isHidden: false
      },
      {
        id: 'billing',
        label: t('ui.entities.finance.billing.label'),
        icon: 'credit-card',
        to: '/settings/dealerships/' + route.params.id + '/billing',
        isHidden: computed(() => userStore.details.type != 1 && !can('manage_dealership_billing'))
      },
      {
        id: 'config',
        label: t('ui.common.configuration'),
        icon: 'cog',
        to: '/settings/dealerships/' + route.params.id + '/config',
        isHidden: computed(() => userStore.details.type != 1)
      }
    ])

    function toggleDayClosed(i) {
      if (dealership.value.openingHours.sales[i].isClosed) {
        dealership.value.openingHours.sales[i].from = null
        dealership.value.openingHours.sales[i].to = null
      }
    }

    // Set tab to 'Basic' if no tab is set
    if (route.params.tab === '') {
      currentTab.value = 'basic'
    } else {
      currentTab.value = route.params.tab
    }

    // If the user changes the tab, update the route
    watch(
      () => currentTab.value,
      newTab => {
        currentTab.value = newTab
        //fetch tab route
        let tab = tabs.value.find(tab => tab.id === newTab)
        if (tab) {
          router.replace(tab.to)
        }
      }
    )

    const inputRules = {
      basicDetails: {
        name: { required },
        displayName: { required },
        locationName: { required },
        manufacturerId: { required },
        groupId: { required },
        telephone: {},
        website: {},
        timeZone: { required },
        address: {
          line1: { required },
          line2: {},
          line3: {},
          city: { required },
          county: { required },
          postcode: { required },
          country: { required }
        }
      },
      features: {
        dataChangeAcknowledge: {},
        leads: {},
        leadsPostHandover: {},
        leadsMotability: {},
        advancedReporting: {},
        switch: {},
        usedStock: {},
        auction: {},
        reloop: {}
      },
      billing: {
        isActive: {}
      },
      config: {
        importSource: {},
        importSourceId: {},
        usedStockLocationSource: {},
        usedStockLocationId: {},
        reAuctionNotificationEmails: {}
      }
    }

    const v$ = useVuelidate(inputRules, dealership)

    const formState = ref({
      basicDetailsSubmitting: false,
      featuresSubmitting: false,
      billingSubmitting: false,
      openingHoursSubmitting: false,
      configSubmitting: false
    })

    const manufacturerLogo = computed(() => {
      if (dealership.value.basicDetails.manufacturerId) {
        let manufacturer = manufacturerOptions.value.find(m => m.value == dealership.value.basicDetails.manufacturerId)
        if (manufacturer) {
          return manufacturer.logo
        }
      }
      return null
    })

    async function submitBasicForm() {
      const isFormCorrect = await v$.value.basicDetails.$validate()
      console.log('isFormCorrect: ', isFormCorrect)

      if (isFormCorrect) {
        formState.value.basicDetailsSubmitting = true

        dealershipSubmitFormRequest
          .sendRequest({
            endpoint: '/v1/dealerships/' + route.params.id,
            method: 'PATCH',
            data: {
              name: dealership.value.basicDetails.name,
              displayName: dealership.value.basicDetails.displayName,
              locationName: dealership.value.basicDetails.locationName,
              manufacturerID: dealership.value.basicDetails.manufacturerId,
              groupID: dealership.value.basicDetails.groupId,
              telephone: dealership.value.basicDetails.telephone,
              website: dealership.value.basicDetails.website,
              timeZone: dealership.value.basicDetails.timeZone,
              address: {
                line1: dealership.value.basicDetails.address.line1,
                line2: dealership.value.basicDetails.address.line2,
                line3: dealership.value.basicDetails.address.line3,
                city: dealership.value.basicDetails.address.city,
                county: dealership.value.basicDetails.address.county,
                postcode: dealership.value.basicDetails.address.postcode,
                country: dealership.value.basicDetails.address.country
              },
              isActive: dealership.value.basicDetails.isActive,
              showInReports: dealership.value.basicDetails.showInReports
            }
          })
          .then(response => {
            if (response.data.response.status == 'success') {
              formState.value.basicDetailsSubmitting = false

              //Notification
              notification('Details Updated')
            }
          })
      }
    }

    async function submitFeaturesForm() {
      const isFormCorrect = await v$.value.features.$validate()
      console.log('isFormCorrect: ', isFormCorrect)

      if (isFormCorrect) {
        formState.value.featuresSubmitting = true

        dealershipSubmitFormRequest
          .sendRequest({
            endpoint: '/v1/dealerships/' + route.params.id,
            method: 'PATCH',
            data: {
              features: {
                dataChangeAcknowledge: dealership.value.features.dataChangeAcknowledge,
                leads: dealership.value.features.leads,
                leadsPostHandover: dealership.value.features.leadsPostHandover,
                leadsMotability: dealership.value.features.leadsMotability,
                advancedReporting: dealership.value.features.advancedReporting,
                switch: dealership.value.features.switch,
                usedStock: dealership.value.features.usedStock,
                auction: dealership.value.features.auction,
                reloop: dealership.value.features.reloop
              }
            }
          })
          .then(response => {
            if (response.data.response.status == 'success') {
              formState.value.featuresSubmitting = false

              //Notification
              notification('Features Updated')
            }
          })
      }
    }

    async function submitBillingForm() {
      const isFormCorrect = await v$.value.billing.$validate()
      console.log('isFormCorrect: ', isFormCorrect)

      if (isFormCorrect) {
        formState.value.billingSubmitting = true

        dealershipSubmitFormRequest
          .sendRequest({
            endpoint: '/v1/dealerships/' + route.params.id,
            method: 'PATCH',
            data: {
              config: {
                billingIsActive: dealership.value.billing.isActive
              }
            }
          })
          .then(response => {
            if (response.data.response.status == 'success') {
              formState.value.billingSubmitting = false

              //Notification
              notification('Billing Updated')
            }
          })
      }
    }

    async function submitOpeningHoursForm() {
      formState.value.openingHoursSubmitting = true
      dealershipSubmitFormRequest
        .sendRequest({
          endpoint: '/v1/dealerships/' + route.params.id,
          method: 'PATCH',
          data: {
            openingHours: {
              sales: dealership.value.openingHours.sales
            }
          }
        })
        .then(response => {
          if (response.data.response.status == 'success') {
            formState.value.openingHoursSubmitting = false

            //Notification
            notification('Opening Hours Updated')
          }
        })
      // }
    }

    async function submitConfigForm() {
      const isFormCorrect = await v$.value.config.$validate()
      console.log('isFormCorrect: ', isFormCorrect)

      if (isFormCorrect) {
        formState.value.configSubmitting = true

        dealershipSubmitFormRequest
          .sendRequest({
            endpoint: '/v1/dealerships/' + route.params.id,
            method: 'PATCH',
            data: {
              config: {
                importSource: dealership.value.config.importSource,
                importSourceId: dealership.value.config.importSourceId,
                usedStockLocationSource: dealership.value.config.usedStockLocationSource,
                usedStockLocationId: dealership.value.config.usedStockLocationId,
                reAuctionNotificationEmails: dealership.value.config.reAuctionNotificationEmails
              }
            }
          })
          .then(response => {
            if (response.data.response.status == 'success') {
              formState.value.configSubmitting = false

              //Notification
              notification('Configuration Updated')
            }
          })
      }
    }

    const addressSearchValue = ref('')

    function onIsActiveToggleChange(value) {
      if (value == false) {
        dealership.value.basicDetails.showInReports = false
      }
    }

    const billingTotals = computed(() => {
      let totals = {
        subTotal: 0,
        discount: 0,
        tax: 0,
        total: 0
      }
      dealership.value.billing.invoiceTemplate?.items?.forEach(item => {
        let itemRate = item.overrideRate ? item.overrideRate : item.catalogItem.saleRate

        totals.subTotal += itemRate * item.quantity

        let discount
        if (item.discountValue && item.discountUnit == 'percent') {
          discount = (item.discountValue / 100) * item.quantity * itemRate
        } else {
          discount = item.discountValue
        }
        totals.discount += discount

        totals.tax += item.catalogItem.taxRate * (itemRate * item.quantity - discount)
      })
      totals.total = totals.subTotal - totals.discount + totals.tax

      return totals
    })

    const invoiceItemsValid = ref(null)

    function validateInvoiceItem(valid) {
      invoiceItemsValid.value = valid
    }

    function saveInvoiceChanges() {
      if (!invoiceItemsValid.value) {
        return
      }
      console.log('saveInvoiceChanges')
      let formattedItems = []

      // build the invoice items to send to the api
      dealership.value.billing.invoiceTemplate.items.forEach(item => {
        formattedItems.push({
          id: item.id,
          overrideRate: item.overrideRate == '' ? null : item.overrideRate,
          quantity: item.quantity,
          discountUnit: item.discountUnit,
          discountValue: item.discountValue == '' ? null : item.discountUnit == 'percent' ? item.discountValue / 100 : item.discountValue
        })
      })

      // send all invoice items to the api
      useApiRequest()
        .sendRequest({
          endpoint: '/v1/finance/invoice-templates/' + dealership.value.billing.invoiceTemplate.id + '/items/bulk-update',
          method: 'POST',
          data: JSON.stringify(formattedItems)
        })
        .then(response => {
          if (response.data.response.status == 'success') {
            //Notification
            notification('Invoice Items Updated')
          }
        })
    }

    return {
      userStore,
      dealership,
      tabs,
      currentTab,
      v$,
      submitBasicForm,
      submitFeaturesForm,
      submitBillingForm,
      submitConfigForm,
      submitOpeningHoursForm,
      formState,
      manufacturerOptions,
      groupOptions,
      countriesList,
      manufacturerLogo,
      addressSearchValue,
      onIsActiveToggleChange,
      users,
      dealershipRequest,
      billingTotals,
      saveInvoiceChanges,
      validateInvoiceItem,
      toggleDayClosed,
      timeZoneList
    }
  }
}
</script>

<style></style>
