<template>
  <PageHeader
    :title="$t('ui.entities.reloop.reloop-scorecard', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.reloop.reloop-scorecard', 2), to: '/reports/reloop/scorecard' }
    ]"
  >
    <template #right>
      <div>
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="dealershipScores"
      removable-sort
      sort-mode="multiple"
      export-filename="ReLoop Scorecard"
      :sort-order="1"
      :rows="20"
      data-key="id"
      :paginator="true"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-reloop-scorecard"
      state-storage="session"
      show-gridlines
      :loading="false"
      :global-filter-fields="['id', 'dealershipId', 'dealershipName']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>
      <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->
      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <router-link
            v-tippy="`View submissions`"
            class="link"
            :to="{ path: '/reports/reloop/submissions', query: { dealerships: data.dealershipId } }"
          >
            {{ data.dealershipName }}
          </router-link>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="submissions" header="Submitted" :sortable="true" />
      <Column field="settlementPercent" header="Settlement" :sortable="true" class="no-padding">
        <template #body="{ data }">
          <div :style="{ 'background-color': 'var(--bg-' + data.settlementColor + ')', color: 'var(--' + data.settlementColor + ')' }">
            {{ numberFormatter(data.settlementPercent) }}%
          </div>
        </template>
      </Column>
      <Column field="valuedPercent" header="Valued" :sortable="true" class="no-padding">
        <template #body="{ data }">
          <div :style="{ 'background-color': 'var(--bg-' + data.valuedColor + ')', color: 'var(--' + data.valuedColor + ')' }">
            {{ numberFormatter(data.valuedPercent) }}%
          </div>
        </template>
      </Column>
      <Column field="testDrivePercent" header="Test Drive" :sortable="true" class="no-padding">
        <template #body="{ data }">
          <div :style="{ 'background-color': 'var(--bg-' + data.testDriveColor + ')', color: 'var(--' + data.testDriveColor + ')' }">
            {{ numberFormatter(data.testDrivePercent) }}%
          </div>
        </template>
      </Column>
      <Column field="metManagementPercent" header="Met Management" :sortable="true" class="no-padding">
        <template #body="{ data }">
          <div
            :style="{
              'background-color': 'var(--bg-' + data.metManagementColor + ')',
              color: 'var(--' + data.metManagementColor + ')'
            }"
          >
            {{ numberFormatter(data.metManagementPercent) }}%
          </div>
        </template>
      </Column>
      <Column field="finalOptionsPercent" header="Final Options" :sortable="true" class="no-padding">
        <template #body="{ data }">
          <div
            :style="{
              'background-color': 'var(--bg-' + data.finalOptionsColor + ')',
              color: 'var(--' + data.finalOptionsColor + ')'
            }"
          >
            {{ numberFormatter(data.finalOptionsPercent) }}%
          </div>
        </template>
      </Column>
      <Column field="totals.totalPercent" header="Score" :sortable="true">
        <template #body="{ data }">
          <span v-tippy="data.totals.totalScore">{{ data.totals.totalPercent }}%</span>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import { ref, computed } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'

import MultiSelect from 'primevue/multiselect'

import dayjs from 'dayjs'

import { useUserStore } from '@/stores/UserStore'
import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'

export default {
  components: { PageHeader, Card, DataTable, Column, Input, Button, MultiSelect, DatePicker },
  setup() {
    const dt = ref()
    const userStore = useUserStore()
    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

    const apiRequest = useApiRequest()
    const reloopsFetched = ref([])

    const dateRange = ref([dayjs().subtract(30, 'day').toDate(), dayjs().toDate()])

    function fetchReloops() {
      apiRequest
        .sendRequest({
          endpoint: '/v1/reloop',
          params: {
            startDate: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
            endDate: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
            dealerships: dealershipsAllowed.value.map(d => d.id).join(',')
          }
        })
        .then(response => {
          reloopsFetched.value = response.data.response.data
        })
    }
    fetchReloops()

    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dealershipId: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    function clearFilters() {
      initFilters()
    }

    const dealershipsList = computed(() => {
      return dealershipsAllowed.value.map(d => {
        return {
          value: d.id,
          label: d.name
        }
      })
    })

    function getScorePercent(score, max) {
      // Check that both numbers are not null
      if (score === null || max === null) {
        return 0
      }
      return (score / max) * 100
    }

    function getScoreColor(score) {
      if (score <= 70) {
        return 'danger'
      } else if (score <= 80) {
        return 'warning'
      } else {
        return 'success'
      }
    }

    const dealershipScores = computed(() => {
      const grouped = reloopsFetched.value.reduce((acc, submission) => {
        const { dealershipId, dealershipName, scores } = submission
        if (!acc[dealershipId]) {
          acc[dealershipId] = {
            dealershipId,
            dealershipName,
            submissions: 0,
            settlement: 0,
            valued: 0,
            testDrive: 0,
            metManagement: 0,
            finalOptions: 0,
            totals: {
              totalScore: 0,
              totalPossible: 0,
              totalPercent: 0
            }
          }
        }

        acc[dealershipId].submissions++
        acc[dealershipId].settlement += scores.answers.settlementGiven
        acc[dealershipId].valued += scores.answers.vehicleValued
        acc[dealershipId].testDrive += scores.answers.testDriveOffered
        acc[dealershipId].metManagement += scores.answers.metManagement
        acc[dealershipId].finalOptions += scores.answers.finalOptionsGiven

        acc[dealershipId].totals.totalScore += scores.totals.totalScore
        acc[dealershipId].totals.totalPossible += scores.totals.totalPossible

        // getScorePercent for each
        acc[dealershipId].settlementPercent = getScorePercent(acc[dealershipId].settlement, acc[dealershipId].submissions)
        acc[dealershipId].settlementColor = getScoreColor(acc[dealershipId].settlementPercent)
        acc[dealershipId].valuedPercent = getScorePercent(acc[dealershipId].valued, acc[dealershipId].submissions)
        acc[dealershipId].valuedColor = getScoreColor(acc[dealershipId].valuedPercent)
        acc[dealershipId].testDrivePercent = getScorePercent(acc[dealershipId].testDrive, acc[dealershipId].submissions)
        acc[dealershipId].testDriveColor = getScoreColor(acc[dealershipId].testDrivePercent)
        acc[dealershipId].metManagementPercent = getScorePercent(acc[dealershipId].metManagement, acc[dealershipId].submissions)
        acc[dealershipId].metManagementColor = getScoreColor(acc[dealershipId].metManagementPercent)
        acc[dealershipId].finalOptionsPercent = getScorePercent(acc[dealershipId].finalOptions, acc[dealershipId].submissions)
        acc[dealershipId].finalOptionsColor = getScoreColor(acc[dealershipId].finalOptionsPercent)

        return acc
      }, {})

      // Calculate total percent
      Object.values(grouped).forEach(dealership => {
        dealership.totals.totalPercent = Math.round((dealership.totals.totalScore / dealership.totals.totalPossible) * 100)
      })

      return Object.values(grouped)
    })

    function onDateRangeChange() {
      // console.log('onDateRangeChange', dateRange.value)
      // Refetch reloops
      fetchReloops()
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    return { dt, dateRange, onDateRangeChange, useBreakPoints, filters, clearFilters, dealershipScores, dealershipsList, exportCSV }
  }
}
</script>

<style></style>
