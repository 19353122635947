<template>
  <div
    v-if="selectionMode == 'single'"
    class="rail select-none"
    :class="{ 'rail-full-width': fullWidth, 'has-error': hasError, disabled: disabled || readonly }"
  >
    <div
      v-for="option in options"
      :key="option.value"
      :for="option.value"
      class="option"
      :class="{ active: option.value == modelValue }"
      @click="optionClicked(option)"
    >
      <Icon v-if="option.icon" :type="option.icon" class="mr-1" />
      {{ option.label }}
    </div>
  </div>

  <div
    v-else-if="selectionMode == 'multiple'"
    class="rail flex gap-2 select-none"
    :class="{ 'rail-full-width': fullWidth, 'has-error': hasError, disabled: disabled || readonly }"
  >
    <div
      v-for="option in options"
      :key="option.value"
      :for="option.value"
      class="option relative"
      :class="{ active: modelValue.includes(option.value) }"
      @click="optionClicked(option)"
    >
      <div>
        <Icon v-if="option.icon" :type="option.icon" class="mr-1" />
        {{ option.label }}
        <div class="absolute -top-[6px] -right-[5px]">
          <Icon v-if="modelValue.includes(option.value)" type="circle-check" class="text-brand" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Icon from '@/components/icon/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      default: 'single' // 'single', 'multiple'
    }
  },
  emits: ['optionChange', 'update:modelValue'],
  setup(props, { emit }) {
    const selectedOptions = ref(props.modelValue)

    function optionClicked(option) {
      if (props.disabled || props.readonly) return
      if (props.selectionMode == 'single') {
        emit('optionChange', option.value)
        emit('update:modelValue', option.value)
      } else {
        if (selectedOptions.value.includes(option.value)) {
          let index = selectedOptions.value.indexOf(option.value)
          if (index > -1) {
            selectedOptions.value.splice(index, 1)
          }
        } else {
          selectedOptions.value.push(option.value)
        }
        emit('optionChange', selectedOptions.value)
        emit('update:modelValue', selectedOptions.value)
      }
    }

    return { optionClicked }
  }
}
</script>

<style scoped>
.rail {
  background-color: var(--bg-secondary_alt);
  display: inline-flex;
  padding: var(--s-1);
  border-radius: var(--rounded-lg);
  border: 1px solid var(--border-secondary);
  position: relative;
  font-size: var(--text-sm);
  overflow: hidden;
}
.rail.rail-full-width {
  width: 100%;
  justify-content: space-between;
}
.rail > .option {
  padding: var(--s-1) var(--s-2);
  border-radius: calc(var(--rounded-md) - 0.1rem);
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--text-tertiary);
}
.rail.rail-full-width > .option {
  flex: 1;
  justify-content: center;
}
.option.active {
  font-weight: 500;
  box-shadow: var(--shadow-sm);
  color: var(--text-secondary);
  background-color: var(--bg-primary_alt);
}
.disabled,
.disabled .option,
.disabled .option:hover {
  cursor: not-allowed;
  color: var(--text-secondary);
}

.rail.has-error {
  color: var(--danger);
  border-color: var(--danger);
  border: 1px solid var(--danger);
}
</style>
