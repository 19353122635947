<template>
  <!-- TODO: Add breadcrumb back in for stage 2 of ReAuction release -->
  <PageHeader title="ReAuction" :items="[{ label: 'ReAuction', to: '/vehicles/reauction' }, { label: listingData?.vehicle?.model }]">
    <template #right>
      <router-link v-if="listingData?.agreementId && canViewAgreement" :to="'/agreement/' + listingData?.agreementId">
        <Button label="View Agreement" outlined severity="secondary" />
      </router-link>
    </template>
  </PageHeader>

  <div v-if="!listingData">
    <div class="flex gap-4 p-4">
      <Skeleton height="100%" width="30%" />
      <Skeleton height="100%" width="30%" />
      <Skeleton height="20%" width="30%" />
    </div>
  </div>

  <div v-else class="grid gap-6 grid-cols-12">
    <div v-if="listingData.sale.stage" class="col-span-12">
      <Chip :severity="stageOptions.find(option => option.value == listingData.sale.stage)?.severity">
        {{ stageOptions.find(option => option.value == listingData?.sale.stage)?.label }}
      </Chip>
    </div>
    <Card class="col-span-12 lg:col-span-9" no-margin>
      <CardBody class="grid grid-cols-12 gap-6">
        <div class="col-span-12 flex justify-between md:hidden">
          <AvatarLabelGroup
            :label="listingData.vehicle.model"
            :description="listingData.vehicle.derivative"
            :avatar-url="listingData.vehicle.manufacturerLogoUrl"
            avatar-is-logo
          />
          <div v-if="listingData.vehicle.vrm">
            <LicensePlate
              :plate="listingData.vehicle.vrm"
              country="uk"
              size="md"
              :design="listingData.vehicle.fuelType == 'electric' ? 'ev' : ''"
              greyscale
            />
          </div>
        </div>

        <div class="col-span-12 md:col-span-7 w-full flex flex-col gap-6">
          <div class="h-[300px] overflow-hidden">
            <div class="relative overflow-hidden w-full h-full flex items-center justify-center rounded-md hover:cursor-pointer">
              <img
                :src="listingData.images ? listingData.images[0] : $cdnBaseUrl + '/img/vehicles/default-car.svg'"
                class="rounded-md w-full h-auto"
                style="user-drag: none"
                @click="listingData.images ? imageClick(0) : ''"
              />
              <div class="absolute top-4 left-4">
                <Chip
                  v-if="listingData.location?.town"
                  v-tippy="listingData.location.county"
                  icon="location-dot"
                  size="sm"
                  class="opacity-90"
                >
                  {{ listingData.location.town }}
                </Chip>
              </div>
            </div>
          </div>

          <Galleria
            v-model:activeIndex="imageIndex"
            v-model:visible="displayCustom"
            :value="listingData.images"
            :num-visible="7"
            container-style="max-width: 850px"
            :circular="true"
            :full-screen="true"
            :show-item-navigators="true"
            :show-thumbnails="true"
          >
            <template #item="slotProps">
              <img :src="slotProps.item" class="w-full max-h-screen block rounded-xl" />
            </template>
            <template #thumbnail="slotProps">
              <img :src="slotProps.item" style="display: block" />
            </template>
          </Galleria>

          <div v-if="listingData.images" class="grid grid-cols-4 grid-flow-row auto-rows-fr gap-2">
            <div
              v-for="(image, index) of listingData.images"
              :key="index"
              class="overflow-hidden max-h-36 rounded-lg hover:shadow-2xl hover:scale-[103%] transition duration-150 ease-in-out"
            >
              <img
                :src="image"
                style="user-drag: none"
                class="cursor-pointer object-cover w-full h-full"
                @click="listingData.images ? imageClick(index) : ''"
              />
            </div>
          </div>
        </div>

        <div class="col-span-12 md:col-span-5 flex flex-col gap-6 w-full">
          <div class="col-span-12 hidden md:contents">
            <div class="flex justify-between">
              <AvatarLabelGroup
                :label="listingData.vehicle.model"
                :description="listingData.vehicle.derivative"
                :avatar-url="listingData.vehicle.manufacturerLogoUrl"
                avatar-is-logo
              />

              <div v-if="listingData.vehicle.vrm">
                <LicensePlate
                  :plate="listingData.vehicle.vrm"
                  country="uk"
                  size="md"
                  :design="listingData.vehicle.fuelType == 'electric' ? 'ev' : ''"
                  greyscale
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3 items-center gap-2">
            <div v-if="listingData.vehicle.fuelType" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <Icon type="gas-pump" class="text-xs" />
                <div class="text-sm text-quaternary font-medium">Fuel Type</div>
              </div>
              <div>{{ listingData.vehicle.fuelType }}</div>
            </div>
            <div v-if="listingData.vehicle.odometer" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <!-- <Icon type="gauge" class="text-xs" /> -->
                <div class="text-sm text-quaternary font-medium">Mileage</div>
              </div>
              <div>{{ numberFormatter(listingData.vehicle.odometer) }}</div>
            </div>
            <div v-if="listingData.vehicle.registrationDate" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <!-- <Icon type="calendar" class="text-xs" /> -->
                <div class="text-sm text-quaternary font-medium">Registration</div>
              </div>
              <div>{{ dayjs(listingData.vehicle.registrationDate).format('L') }}</div>
            </div>
            <div v-if="listingData.vehicle.transmission" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <!-- <Icon type="car-wrench" class="text-xs" /> -->
                <div class="text-sm text-quaternary font-medium">Gearbox</div>
              </div>
              <div>{{ listingData.vehicle.transmission }}</div>
            </div>
            <div v-if="listingData.vehicle.doors" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <!-- <Icon type="door-open" class="text-xs" /> -->
                <div class="text-sm text-quaternary font-medium">Doors</div>
              </div>
              <div>{{ listingData.vehicle.doors }}</div>
            </div>
            <div v-if="listingData.vehicle.seats" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <!-- <Icon type="door-open" class="text-xs" /> -->
                <div class="text-sm text-quaternary font-medium">Seats</div>
              </div>
              <div>{{ listingData.vehicle.doors }}</div>
            </div>
            <div v-if="listingData.vehicle.previousOwners" class="reauction-listing__detail-box">
              <div class="flex gap-1 items-center">
                <!-- <Icon type="user" class="text-xs" /> -->
                <div class="text-sm text-quaternary font-medium">Owners</div>
              </div>
              <div>{{ listingData.vehicle.previousOwners }}</div>
            </div>
          </div>

          <div>
            <div v-if="listingData.capValuation?.valuationList.valuedAt" class="flex justify-end mb-2">
              <div v-tippy="dayjs(listingData.capValuation.valuationList.valuedAt).format('LLLL')" class="text-quaternary text-sm">
                updated {{ dayjs().to(listingData.capValuation.valuationList.valuedAt) }}
              </div>
            </div>
            <ListGroup v-if="listingData.capValuation?.valuationList">
              <ListGroupItem class="flex justify-between">
                <div>CAP clean</div>
                <div>
                  {{
                    listingData.capValuation.valuationList.tradeAverage
                      ? numberFormatter(listingData.capValuation.valuationList.tradeAverage, 'currency', 2, listingData.currencyCode)
                      : '-'
                  }}
                </div>
              </ListGroupItem>
              <ListGroupItem class="flex justify-between">
                <div>RRP</div>
                <div>
                  {{
                    listingData.capValuation.valuationList.tradeRetail
                      ? numberFormatter(listingData.capValuation.valuationList.tradeRetail, 'currency', 2, listingData.currencyCode)
                      : '-'
                  }}
                </div>
              </ListGroupItem>
            </ListGroup>
          </div>

          <div class="flex justify-start">
            <div class="link-hover flex gap-2 items-center select-none" @click="toggleMoreInfo">
              <div>{{ isMoreInfoShown ? 'MOT and Service info' : 'MOT and Service info' }}</div>
              <Icon :type="isMoreInfoShown ? 'chevron-up' : 'chevron-down'" />
            </div>
          </div>
          <div v-if="isMoreInfoShown">
            <div class="flex items-center gap-2"><Icon type="wrench" /> Service</div>
            <div class="grid grid-cols-2 gap-4 items-center mt-4">
              <div class="border border-border-secondary p-6 rounded flex flex-col items-center justify-center h-full">
                <Icon type="book-open" class="text-2xl" />
                <div class="text-sm text-center">Has Service Book</div>
                <Icon v-if="listingData.vehicle.maintenance.hasServiceBook === null" type="circle-question" class="text-grey text-2xl" />
                <Icon v-else-if="listingData.vehicle.maintenance.hasServiceBook" type="circle-check" class="text-success text-2xl" />
                <Icon v-else type="circle-xmark" class="text-warning text-2xl" />
              </div>
              <div class="border border-light p-6 rounded flex flex-col items-center justify-center h-full">
                <Icon type="car-wrench" class="text-2xl" />
                <div class="text-sm text-center">Full Franchised Service</div>
                <Icon
                  v-if="listingData.vehicle.maintenance.hasFullFranchisedService === null"
                  type="circle-question"
                  class="text-grey text-2xl"
                />
                <Icon
                  v-else-if="listingData.vehicle.maintenance.hasFullFranchisedService"
                  type="circle-check"
                  class="text-success text-2xl"
                />
                <Icon v-else type="circle-xmark" class="text-warning text-2xl" />
              </div>
            </div>

            <ListGroup class="mt-4">
              <ListGroupItem class="flex justify-between">
                <div>Last Service</div>
                <div>
                  {{
                    listingData.vehicle.maintenance.lastServiceDate
                      ? toReadableDateTime(listingData.vehicle.maintenance.lastServiceDate, 'date')
                      : '-'
                  }}
                </div>
              </ListGroupItem>
              <ListGroupItem class="flex justify-between">
                <div>Last Service Mileage</div>
                <div>{{ listingData.vehicle.maintenance.lastServiceMileage || '-' }}</div>
              </ListGroupItem>
            </ListGroup>

            <div class="flex items-center justify-between mt-8">
              <div class="flex items-center gap-2"><Icon type="engine" /> MOT</div>
              <div class="text-secondary text-sm">
                Last Synced:
                {{
                  listingData.vehicle.maintenance.motFetchedAt
                    ? toReadableDateTime(listingData.vehicle.maintenance.motFetchedAt, 'date')
                    : 'never'
                }}
              </div>
            </div>
            <ListGroup v-if="listingData.countryCode === 'GB'" class="mt-4">
              <ListGroupItem class="flex justify-between">
                <div>Last MOT</div>
                <div>
                  {{
                    listingData.vehicle.maintenance.lastMotDate
                      ? toReadableDateTime(listingData.vehicle.maintenance.lastMotDate, 'date')
                      : '-'
                  }}
                </div>
              </ListGroupItem>
              <ListGroupItem class="flex justify-between">
                <div>Next MOT</div>
                <div>
                  {{
                    listingData.vehicle.maintenance.nextMotDueDate
                      ? toReadableDateTime(listingData.vehicle.maintenance.nextMotDueDate, 'date')
                      : '-'
                  }}
                </div>
              </ListGroupItem>
            </ListGroup>

            <div v-for="test in listingData.vehicle.maintenance.motHistory" :key="test" class="my-6">
              <div class="flex">
                <Icon v-if="test.TestResult" type="circle-check" class="text-success text-lg" />
                <div class="ml-2">
                  <div>
                    <div class="text-lg font-medium">Pass</div>
                    <div class="text-secondary text-sm">
                      {{ toReadableDateTime(dayjs(test.TestDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'date') }} |
                      {{ numberFormatter(test.OdometerReading) }}{{ test.OdometerUnit }}
                    </div>
                  </div>
                  <div v-for="advisory in test.AdvisoryNoticeList" :key="advisory" class="mt-1 flex">
                    <Icon type="circle" class="text-[0.4rem] mr-2 mt-[0.35rem] text-secondary" />{{ advisory }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>

    <div class="col-span-12 md:col-span-6 lg:col-span-3">
      <Card>
        <CardBody class="flex flex-col gap-6">
          <div class="flex justify-between items-start">
            <div v-if="listingData.type == 'sell'" class="text-lg">{{ listingData.sale.offers || 0 }} offers</div>

            <div v-if="isListingExpired" class="text-error text-lg">Expired</div>
            <div v-else-if="listingData.sale.status == 'paused'" class="text-warning text-lg">Paused</div>
            <div v-else-if="listingData.sale.currentWindowExpiresAt" class="flex flex-col items-end">
              <div class="text-sm leading-4">Time left</div>
              <div class="text-lg font-quaternary" :class="timerColor">
                {{ listingTimeLeft }}
              </div>
            </div>
          </div>

          <!-- TODO: Unhide when placing an offer is possible -->
          <div class="hidden flex flex-col gap-4">
            <Input
              v-model="inputBidAmount"
              :icon="listingData.currencyCode.toLowerCase()"
              :readonly="isListingExpired || listingData.sale.status == 'paused'"
              class="w-full"
            />
            <Button label="Submit offer" outlined />
          </div>
        </CardBody>
      </Card>
      <div v-if="!isListingExpired && listingData.sale.status == 'active'" class="w-full hidden">
        <Button label="Not interested" outlined class="w-full" severity="danger" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import Input from '@/components/forms/Input.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import LicensePlate from '@/components/license-plate/LicensePlate.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'

import Skeleton from 'primevue/skeleton'
import Galleria from 'primevue/galleria'

import { useUserStore } from '@/stores/UserStore'
import { toReadableDateTime } from '@/utilities/timeFormatterUtil'
import useApiRequest from '@/composables/useApiRequest'
import numberFormatter from '@/scripts/numberFormatter'
import notification from '@/utilities/notification.js'

import dayjs from 'dayjs'

const route = useRoute()
const userStore = useUserStore()

const listingId = ref(route.params.id)

const listingData = ref()
const fetchListingApiRequest = useApiRequest()

const isMoreInfoShown = ref(false)
const inputBidAmount = ref(null)
const imageIndex = ref(0)
const displayCustom = ref(false)

const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const canViewAgreement = ref(false)

const isListingExpired = ref(false)
const listingTimeLeft = ref(null)
const timerColor = ref(null)

const stageOptions = [
  { value: 'awaiting_images', label: 'Awaiting images', severity: 'error' },
  { value: 'offering_dealership', label: 'Offering to dealership', severity: 'info' },
  { value: 'offering_group', label: 'Offering to group', severity: 'info' },
  { value: 'offering_national', label: 'Offering nationally', severity: 'info' },
  { value: 'awaiting_offer_acceptance', label: 'Awaiting offer acceptance', severity: 'warning' }
]

function toggleMoreInfo() {
  isMoreInfoShown.value == true ? (isMoreInfoShown.value = false) : (isMoreInfoShown.value = true)
}

const imageClick = index => {
  imageIndex.value = index
  displayCustom.value = true
}

fetchListingApiRequest.sendRequest({ endpoint: '/v1/valuations/' + listingId.value }).then(response => {
  if (response.data.response.status != 'success') {
    notification('Error!', 'Failed to fetch ReAuction', 'danger')
    return
  }
  listingData.value = response.data.data
  listingTimer()
  if (dealershipsAllowed.value.map(d => d.id).find(id => id == listingData.value.dealershipId)) {
    canViewAgreement.value = true
  }
})

function listingTimer() {
  listingTimeLeft.value = dayjs().to(dayjs(listingData.value.sale.currentWindowExpiresAt), true)
  if (listingData.value.sale.status == 'expired' || dayjs(listingData.value.sale.currentWindowExpiresAt).isBefore(dayjs())) {
    isListingExpired.value = true
  }
  if (dayjs(listingData.value.sale.currentWindowExpiresAt).diff(dayjs(), 'minutes', true) < 60) {
    timerColor.value = 'text-error'
  } else if (dayjs(listingData.value.sale.currentWindowExpiresAt).diff(dayjs(), 'hours', true) < 6) {
    timerColor.value = 'text-warning'
  } else {
    timerColor.value = 'text-success'
  }
}

const timerInterval = setInterval(listingTimer, 5000)

watch(isListingExpired => {
  if (isListingExpired.value == true) {
    clearInterval(timerInterval)
  }
})
</script>

<style>
.reauction-listing__detail-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--s-2) var(--s-4);
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-md);
  background-color: var(--bg-secondary);
}
</style>
