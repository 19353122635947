<template>
  <PageHeader
    title="Agent Statistics"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.user.role.call-agent', 2) },
      { label: $t('ui.common.statistic', 2), to: '/reports/call-agents/statistics' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Agent Statistics"
      :value="agentStatistics"
      removable-sort
      :rows="100"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-agent-statistics"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <div class="toggle-container">
              <Switch v-model="showConversionRates" size="sm" />
              <div class="font-medium">Show Conversion Rates</div>
            </div>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>
      <Column field="userName" header="Name" hidden></Column>

      <Column field="userId" header="Name" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }"> {{ data.userName }} </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              <Avatar :url="slotProps.option.profilePicUrl" :text="slotProps.option.initials" size="sm" class="mr-3" />
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="productiveHours" header="Prod Hours" :sortable="true">
        <template #body="{ data }"> {{ data.productiveHours }} </template>
      </Column>

      <Column field="targetCalls" header="Call Target" :sortable="true">
        <template #body="{ data }"> {{ data.targetCalls }} </template>
      </Column>

      <Column field="stats.calls.attempted" header="Calls Attmpt" :sortable="true">
        <template #body="{ data }"> {{ data.stats.calls.attempted }} </template>
      </Column>

      <Column v-if="showConversionRates" field="stats.conversionRates.callsAttemptedVsTarget" :sortable="true">
        <template #header>
          <div v-tippy="{ content: 'Calls Attempted VS Target' }">
            %
            <Icon type="circle-info" fa-style="far" />
          </div>
        </template>
        <template #body="{ data }">
          <Chip
            v-tippy="{ content: 'Calls Attempted VS Target' }"
            :severity="getConversionColour(data.stats.conversionRates.callsAttemptedVsTarget * 100)"
          >
            {{ numberFormatter(data.stats.conversionRates.callsAttemptedVsTarget * 100, 'normal', 0) }}%
          </Chip>
        </template>
      </Column>

      <Column field="stats.calls.averageLengthNoAnswer" header="Avg Length (No Answ)" :sortable="true">
        <template #body="{ data }">
          {{ $dayjs.duration(data.stats.calls.averageLengthNoAnswer, 'seconds').format('mm:ss') }}
        </template>
      </Column>

      <Column field="stats.calls.answered" header="Calls Answ" :sortable="true">
        <template #body="{ data }">
          {{ data.stats.calls.answered }}
        </template>
      </Column>

      <Column v-if="showConversionRates" field="stats.conversionRates.callsAnsweredVsAttempted" :sortable="true">
        <template #header>
          <div v-tippy="{ content: 'Calls Answered VS Attempted' }" class="min-w-9">
            %
            <Icon type="circle-info" fa-style="far" />
          </div>
        </template>
        <template #body="{ data }">
          <Chip
            v-tippy="{ content: 'Calls Answered VS Attempted' }"
            :severity="getConversionColour(data.stats.conversionRates.callsAnsweredVsAttempted * 100)"
          >
            {{ numberFormatter(data.stats.conversionRates.callsAnsweredVsAttempted * 100, 'normal', 0) }}%
          </Chip>
        </template>
      </Column>

      <Column field="stats.calls.opportunity" header="Oppt" :sortable="true">
        <template #body="{ data }">
          {{ data.stats.calls.opportunity }}
        </template>
      </Column>

      <Column field="stats.calls.averageLengthOpportunity" header="Avg Length (Opp)" :sortable="true">
        <template #body="{ data }">
          {{ $dayjs.duration(data.stats.calls.averageLengthOpportunity, 'seconds').format('mm:ss') }}
        </template>
      </Column>

      <Column v-if="showConversionRates" field="stats.conversionRates.opportunitiesVsAnswered" :sortable="true">
        <template #header>
          <div v-tippy="{ content: 'Opportunity Calls VS Answered' }" class="min-w-9">
            %
            <Icon type="circle-info" fa-style="far" />
          </div>
        </template>
        <template #body="{ data }">
          <Chip
            v-tippy="{ content: 'Opportunity Calls VS Answered' }"
            :severity="getConversionColour(data.stats.conversionRates.opportunitiesVsAnswered * 100)"
          >
            {{ numberFormatter(data.stats.conversionRates.opportunitiesVsAnswered * 100, 'normal', 0) }}%
          </Chip>
        </template>
      </Column>

      <Column field="stats.appointments.made" header="Appts Made" :sortable="true">
        <template #body="{ data }">
          {{ data.stats.appointments.made }}
        </template>
      </Column>

      <Column v-if="showConversionRates" field="stats.conversionRates.opportunitiesVsApptsMade" :sortable="true">
        <template #header>
          <div v-tippy="{ content: 'Opportunity Calls VS Appointments Made' }" class="min-w-9">
            %
            <Icon type="circle-info" fa-style="far" />
          </div>
        </template>
        <template #body="{ data }">
          <Chip
            v-tippy="{ content: 'Opportunity Calls VS Appointments Made' }"
            :severity="getConversionColour(data.stats.conversionRates.opportunitiesVsApptsMade * 100)"
          >
            {{ numberFormatter(data.stats.conversionRates.opportunitiesVsApptsMade * 100, 'normal', 0) }}%
          </Chip>
        </template>
      </Column>

      <Column field="stats.appointments.occurring" header="Appts Occur" :sortable="true">
        <template #body="{ data }">
          {{ data.stats.appointments.occurring }}
        </template>
      </Column>

      <Column field="stats.appointments.attended" header="Attd" :sortable="true">
        <template #body="{ data }">
          {{ data.stats.appointments.attended }}
        </template>
      </Column>

      <Column v-if="showConversionRates" field="stats.conversionRates.attendedVsOccurring" :sortable="true">
        <template #header>
          <div v-tippy="{ content: 'Attended VS Occurring' }" class="min-w-9">
            %
            <Icon type="circle-info" fa-style="far" />
          </div>
        </template>
        <template #body="{ data }">
          <Chip
            v-tippy="{ content: 'Attended VS Occurring' }"
            :severity="getConversionColour(data.stats.conversionRates.attendedVsOccurring * 100)"
          >
            {{ numberFormatter(data.stats.conversionRates.attendedVsOccurring * 100, 'normal', 0) }}%
          </Chip>
        </template>
      </Column>

      <ColumnGroup type="footer">
        <Row>
          <Column :colspan="1" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ $t('ui.common.total', 1) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length">
              {{ tableData.map(d => d.productiveHours || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.targetCalls || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.stats.calls.attempted || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="showConversionRates" footer-style="padding: 14px 21px;"> </Column>
          <Column footer-style="padding: 14px 21px;"> </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.stats.calls.answered || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="showConversionRates" footer-style="padding: 14px 21px;"> </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.stats.calls.opportunity || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;"> </Column>
          <Column v-if="showConversionRates" footer-style="padding: 14px 21px;"> </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.stats.appointments.made || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column v-if="showConversionRates" footer-style="padding: 14px 21px;"> </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.stats.appointments.occurring || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template v-if="tableData.length" #footer>
              {{ tableData.map(d => d.stats.appointments.attended || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
        </Row>
      </ColumnGroup>
      <Column v-if="showConversionRates" footer-style="padding: 14px 21px;"> </Column>
    </DataTable>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import Switch from '@/components/forms/Switch.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { FilterMatchMode } from 'primevue/api'

import MultiSelect from 'primevue/multiselect'

import dayjs from 'dayjs'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'

import { ref, computed } from 'vue'

export default {
  components: {
    Card,
    PageHeader,
    DataTable,
    Icon,
    Column,
    DatePicker,
    Input,
    Button,
    Chip,
    Switch,
    MultiSelect,
    ColumnGroup,
    Row
  },
  setup() {
    const dt = ref({ processedData: [] })
    const userStore = useUserStore()

    const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
    const statisticsApiRequest = useApiRequest()

    const agentStatistics = ref([])

    const showConversionRates = ref(false)
    const users = ref([])
    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userId: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    function clearFilters() {
      initFilters()
    }

    function fetchStatistics() {
      statisticsApiRequest
        .sendRequest({
          endpoint: '/v1/reports/call-agents/statistics',
          params: {
            start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
            end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
            userType: userStore.details.type
          }
        })
        .then(response => {
          agentStatistics.value = response.data.data
        })
    }
    fetchStatistics()

    function onDateRangeChange() {
      if (dateRange.value[0] && dateRange.value[1]) {
        fetchStatistics()
      }
    }

    // Users List
    const usersApiRequest = useApiRequest()
    usersApiRequest.sendRequest({ endpoint: '/v1/users?userType=retain' }).then(response => {
      users.value = response.data.data
      console.log(users)
    })

    const usersList = computed(() => {
      // if no users, return empty array
      if (!users.value) return []

      return users.value.map(u => {
        return {
          value: u.id,
          label: u.fullName,
          profilePicUrl: u.profilePicURL,
          initials: u.initials
        }
      })
    })

    const tableData = computed(() => {
      return dt.value.processedData
    })

    const getConversionColour = num => {
      return num >= 95 ? 'success' : 'danger'
    }

    const exportCSV = () => {
      // TODO: create a custom csv composable that can be passed a header, rows and footer.
      // Reason for this is that primevuew has no way to define the key to be used for the column. eg we want createdByName to be used for createdBy
      // For now, we will use the default exportCSV method for simplicity.

      dt.value.exportCSV()
    }

    return {
      dt,
      useBreakPoints,
      dateRange,
      agentStatistics,
      onDateRangeChange,
      filters,
      exportCSV,
      clearFilters,
      showConversionRates,
      getConversionColour,
      usersList,
      tableData
    }
  }
}
</script>

<style>
.toggle-container {
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-md);
  padding: var(--s-2) var(--s-3) var(--s-2) var(--s-2);
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-secondary);
}
</style>
