<template>
  <DataTable
    ref="dt"
    v-model:selection="selectedDealerships"
    v-model:filters="filters"
    export-filename="Dealerships"
    :value="modelValue"
    :loading="isLoading"
    removable-sort
    sort-field="id"
    :sort-order="-1"
    :rows="20"
    data-key="id"
    column-resize-mode="fit"
    :paginator="true"
    responsive-layout="scroll"
    filter-display="menu"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-settings-dealerships"
    state-storage="session"
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    :global-filter-fields="['id', 'displayName', 'groupName', 'name', 'assignedPhoneNumber.number']"
  >
    <template #empty> No records found </template>
    <template #header>
      <div class="flex justify-between">
        <span class="flex justify-between gap-4">
          <span>
            <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
          </span>
          <Button
            :label="$t('ui.actions.clear', 1)"
            severity="secondary"
            type="button"
            outlined
            icon="filter-slash"
            @click="clearFilter()"
          />
        </span>
      </div>
    </template>

    <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->

    <Column field="id" header="#" :sortable="true">
      <template #body="{ data }">
        <a class="link" :href="'/settings/dealerships/' + data.id">{{ data.id }}</a>
      </template>
    </Column>

    <Column field="displayName" header="Name" :sortable="true">
      <template #body="{ data }">
        <div class="flex items-center hover:text-primary cursor-pointer" @click="$router.push('/settings/dealerships/' + data.id)">
          <Avatar :url="data.manufacturerLogo" is-logo></Avatar>
          <div class="ml-3">
            <div>{{ data.displayName }}</div>
            <div class="text-xs text-secondary">{{ data.groupName }}</div>
          </div>
        </div>
      </template>
    </Column>

    <Column field="assignedPhoneNumber.numberFull" header="Assigned Number" :sortable="true">
      <template #body="{ data }">
        <div>{{ data.assignedPhoneNumber?.numberFull }}</div>
      </template>
    </Column>

    <Column field="isActive" header="Status" :sortable="true">
      <template #body="{ data }">
        <Switch v-if="$can('manage_dealerships')" v-model="data.isActive" @change="val => toggleIsActive(val, data.id)" />
        <Chip v-else-if="data.isActive" severity="success" label size="sm">Active</Chip>
        <Chip v-else label size="sm">Disabled</Chip>
      </template>
    </Column>

    <Column header="" :sortable="false">
      <template #body="slotProps">
        <div class="flex">
          <Button
            v-tippy="$t('ui.common.details')"
            icon="cog"
            rounded
            plain
            severity="secondary"
            @click="$router.push('/settings/dealerships/' + slotProps.data.id)"
          ></Button>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Switch from '@/components/forms/Switch.vue'
import Chip from '@/components/chip/Chip.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import useApiRequest from '@/composables/useApiRequest'
import notification from '@/utilities/notification.js'

import { ref } from 'vue'

export default {
  components: {
    Input,
    Button,
    DataTable,
    Column,
    Avatar,
    Switch,
    Chip
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['dealership-updated'],
  setup(props) {
    const dt = ref({ processedData: [] })
    const dealershipApiRequest = useApiRequest()
    const accessModalIsOpen = ref(false)

    const selectedDealerships = ref([])
    const onRowSelect = event => {
      console.log('Selected ' + event.data.name)
    }

    const onRowUnselect = event => {
      console.log('Unselected ' + event.data.name)
    }

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rolesNamed: { value: null, matchMode: FilterMatchMode.IN }
    })

    const clearFilter = () => {
      // console.log('Clearing Filter')
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rolesNamed: { value: null, matchMode: FilterMatchMode.IN }
      }
    }

    const dealershipBeingActioned = ref({
      id: null
    })

    function toggleIsActive(val, id) {
      dealershipApiRequest
        .sendRequest({
          endpoint: '/v1/dealerships/' + id,
          method: 'PATCH',
          data: {
            isActive: val
          }
        })
        .then(response => {
          if (response.data.response.status != 'success') {
            notification('Error', 'Dealership could not be updated', 'danger')
            // Set value to what it was before toggle
            let dealership = props.modelValue.find(d => d.id == id)
            dealership.isActive = !val
          }
        })
    }

    return {
      dt,
      filters,
      clearFilter,
      selectedDealerships,
      onRowSelect,
      onRowUnselect,
      accessModalIsOpen,
      dealershipBeingActioned,
      toggleIsActive
    }
  }
}
</script>
